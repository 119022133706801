//color-set
$colorOne: #005a8c;
$colorTwo: #EF8200;
$colorThree: #702C6A;
$colorFour: #5a8e22;
$colorFive: #901423;
$colorSeven: #f3f5f7;
$colorEight: #ddd;


//fonts-size
$xs-font: 0.5em;
$s-font: 0.8em;
$r-font: 1em;
$rrmd-font:1.12em;
$rmd-font: 1.25em;
$md-font: 1.5em;
$lg-font: 2em;

.attest_font1 {
  font-family: Brush Script MT, cursive;
  font-size: $md-font;
  color: #000;
}

.attest_font2 {
  font-family: Georgia, serif;
  font-style: italic;
  font-size: $rmd-font;
  color: #000;
}

.attest_font3 {
  font-family: Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, sans-serif;
  font-style: italic;
  font-size: $rmd-font;
  color: #000;
}

//Margins
$xs: 0.6em;
$s: 1em;
$md: 1.5em;
$lg: 2em;
$lg-xl: 2.5em;
$xl: 3em;

body {
  margin: 0;
  background-color: $colorSeven;
  font-size: 1em;
  line-height: 1.4;
  box-sizing: border-box
}


h1, h2, h3, h4, h5 {
  font-weight: 200;
  margin: 0px;
  color: $colorOne;
  text-align: left;
}

h1 {
  color: #000;
  font-size: $lg-font;
}

h4 {
  color: #000;
}

h5 {
  color: $colorOne;
  font-size: $rmd-font;
  font-weight: 300;
}

h6 {
  color: #000;
  font-size: $s-font;
  font-weight: normal;
}

h6.benefit-subheader {
  color: $colorOne;
  font-size: $rrmd-font;
  font-weight: 300;
  margin: 5px 0px;
}

p {
  margin: 0px;
}
.small{
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  font-weight: 600;
}
/* .text-left {text-align: left;} */

sup {
  top: 2px;
  position: relative;
  line-height: 0px;
  vertical-align: super;
  font-size: smaller;
}

/* sup {
  vertical-align: super;
  font-size: smaller;
} */


/*Modal*/
.modal-header, .modal-footer {
  border: none;
  font-weight: bold;
  padding: 22px;
}

.modal-body {
  text-align: center;
  padding: 22px;

}

.modal-title {
  text-align: center !important;
}

.padding-r-md {
  padding-right: 1.5em;
}

.m-p-0 {
  padding: 0px;
}

.preview-box {
  clear: both;
  padding-top: 15px;
  margin-left: 2px;
}

.preview-value {
  float: left;
  word-break: break-all;

}

.preview-label {
  float: left;
  width: 233px;
  text-align: left;
  color: #666666;
  font-size: $r-font;
  font-weight: 100;
}

.preview-text {
  color: #48721B;
  font-size: $r-font;
  padding-left: 15px;
  font-weight: 500;
  word-wrap: break-word;

}

.fileList {
  color: #48721B;
  font-size: 1em;
}

.fileList a {
  color: #a7a4a4;
  font-size: small;
}

.fileList a:link {
  color: #a7a4a4;
  font-size: small;
}

.fileList a:hover {
  color: #a7a4a4;
  font-size: small;
}

/*utilies*/
.clear:after {
  clear: both;
  content: "";
  display: table;
}

.inline-block {
  display: inline-block;
  box-sizing: border-box;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.padding4 {
  padding: 4px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left !important;
}

a {
  text-decoration: none;

}

a:hover {
  text-decoration: underline;

}

ul, ol {
  margin-top: 0;
  padding-left: 30px;
}

ul.no-style, ol.no-style {
  padding-left: 0;

  li {
    list-style: none;
  }
}

ul.inline-block li {
  display: inline-block;
}


.margin-r-md {
  margin-right: $md;
}

.margin-l-md {
  margin-left: $md;
}

.margin-l-lg-xl {
  margin-left: $lg-xl;
}

.margin-r-s {
  margin-right: $s;
}

.margin-r-lg {
  margin-right: $lg;
}

.margin-r-20 {
  margin-right: 20px;
}

.margin-r-lg-xl {
  margin-right: $lg-xl;
}

.margin-r-xl {
  margin-right: $xl;
}

.margin-t-lg {
  margin-top: $lg;
}

.margin-t-md {
  margin-top: $md;
}

.margin-t-sm {
  margin-top: $s;
}


.margin-b-md {
  margin-bottom: $md;
}

/* .padding-r-md{
  padding-right: $md;
} */
.padding-l-md {
  padding-left: $md;
}

/* .padding-r-md{
  padding-right: $md;
} */
.padding-l-xs {
  padding-left: $xs;
}

.padding-r-xs {
  padding-right: $xs;
}

.padding-0 {
  padding: 0px !important;
}

/*Columns*/
.two-col {
  width: 50%;
}

.two-col-spaced {
  width: 48%;
  margin: 1%;
}

.two-sm-col-spaced {
  width: 50%;

}

.two-sm-col-spaced-r {
  width: 44%;
  padding-left: $md;
}

.two-col-spaced-nomargin {
  width: 48%;
  margin: 1%;
  margin-left: 0%;
}

.three-col {
  width: 33.33%;
}

.three-col-spaced {
  width: 30.33%;
  margin: 1%;
  // min-height: 200px;
}

.quarter {
  width: 25%;
}

.three-quarter {
  width: 75%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.block-center {
  margin: auto;
  display: block;
}

.txt-s {
  font-size: $s-font;
}


/*BODY LAYOUT*/
.main-content {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin: auto;
  background-color: $colorSeven;
}

.main-content-wrapper {
  padding: 2em 0px;
  max-width: 1100px;
  width: 100%;
  display: block;
  margin: auto;
}

.clr {
  clear: both;
}

.twoCol {
  float: left;
  width: 50%;
}

/* .block-center{
  margin: auto;
  display: block;
} */
.flow-option-block {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid #5b7c93;
  padding: 30px;
  margin-bottom: 30px;
  background: #fff;
}

.MuiFormControl-root {
  width: 100% !important;
}

/*BUTTONS*/
.MuiButton-root {
  padding: 0px !important;
  width: 100% !important;
}

.button {
  color: #fff;
  padding: 12px;
  text-transform: none !important;
  font-size: $r-font;
  width: 100% !important;

  &:disabled, &:disabled:hover {
    background-color: #999;
    cursor: default;
  }
}

.MuiButton-contained.Mui-disabled {
  background-color: #999 !important;
}

.btn-primary {

  background-color: $colorFour;

  &:hover {
    background-color: darken($colorFour, 10%);
  }
}

.btn-primaryTwo {

  background-color: $colorOne;

  &:hover {
    background-color: darken($colorOne, 10%);
  }
}

.btn-primaryThree {

  background-color: $colorThree;

  &:hover {
    background-color: darken($colorThree, 10%);
  }
}

.btn-secondary {
  color: $colorFour;
  border: 1px solid $colorFour;
  background-color: #fff;

  &:hover {
    color: darken($colorFour, 10%);
    border: 1px solid darken($colorFour, 10%);
  }

  i {
    fill: $colorFour;
  }
}

.btn-disable {
  background-color: #dddddd;

  &:hover {
    background-color: darken(#dddddd, 10%);
  }
}

/*HEADER AND FOOTER*/

.header, .footer {
  width: 100%;
  display: block;
  box-sizing: border-box;
  height: 78px;
  background-color: #fdfdfd;
  border-bottom: 1px solid #fff;
}

.main-header {
  max-width: 100%;
  width: 100%;
  font-size: 0.8em;
  display: block;
  margin: 0 auto;

}

.header-wrap {
  max-width: 1100px;
  width: 100%;
  padding: 0px;
  font-size: 0.8em;
  display: block;
  margin: 0 auto;
}

/* .footer-wrap{  
  width: 100%;
  padding: 2px 0px;
   
  display:block;
  margin: 0 auto;
} */

.secondary-links-wrap {
  width: 100%;
  display: block;
  margin: 0 auto;

}

.secondary-links-nav {
  padding: 2px 0px;
  color: #fff;
}

/*HEADER*/
.logo-wrap {
  width: 180px;
  display: block;
  margin: 0 auto;
  padding-top: 0px;
  float: left;
}

.header-nav {
  float: right;
  width: 85%;
}

/*CENTER HEADER*/
.header.header-center {
  .header-wrap {
    text-align: center;
  }

  .logo-wrap {
    display: inline-block;
    float: none;

  }

  .header-logo {
    display: block;
    margin: auto;

  }

  .header-nav {
    width: 100%;
    display: block;
    margin-top: 2px;

  }

  .header-nav .menu-links {
    text-align: center;
  }

}

.header-logo {
  max-width: 178px;
  width: 100%;
  height: 70px;
  background-image: url(img/logo.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.menu-links.right {
  text-align: right;
}

.menu-links.center {
  text-align: center;
}

.header-navigation a {
  padding: 0px 2px;
}

.header-navigation a:first-of-type {
  padding: 0px 2px 0px 0px;
}

.header-navigation a:last-of-type {
  padding: 0px 0px 0px 2px;
}

/*Footer*/

.main-footer {
  color: #333;
  max-width: 100%;
  width: 100%;
  padding: 2em 0px;
  font-size: 0.8em;
  display: block;
  margin: 0 auto;
  bottom: 0;
  position: fixed;
  background: $colorEight;
}

.footer {
  text-align: center;
}

.footerText a {
  color: #333;
  text-decoration: none;
}

.footerText :hover {
  text-decoration: underline;
}

.secondary-links {
  width: 100%;
  display: block;
  box-sizing: border-box;

}

.footer ul {
  padding: 0px;
  margin: 0px;
}

.footer ul li {
  list-style: none;
}

.footer-links {
  &-5 .nav {
    width: 20%;
    float: left;
  }

  &-4 .nav {
    width: 25%;
    float: left;
  }

  &-3 .nav {
    width: 33.33%;
    float: left;
  }

}

.footer-wrap {
  width: 100%;
  padding: 2px 0px;
  display: block;
  margin: 0 auto;

  .f-b {
    padding: 2px 0px;

  }

  .f-b:first-of-type {
    padding: 0px 0px 2px 0px;

  }

  .f-b:last-of-type {
    border: none;
    padding: 2px 0px 0px 0px;

  }

}

.inline-copy p {
  display: inline-block;
  margin-right: 2px;
  margin-top: 0px;
}

.footer ul.inline-block li {
  padding: 0px 2px;

  &:first-of-type {
    padding: 0px 2px 0px 0px;
  }

  &:last-of-type {
    border: none;
    padding: 0px 0px 0px 2px;
  }
}

.footer-logos {
  &-left {
    float: left;
    width: 20%;
    text-align: left;
  }

  &-right {
    float: right;
    width: 20%;
    text-align: right;
  }

  &-mid {
    float: right;
    width: 50%;

  }
}

.privacy-copyright-wrapper {
  &-left {
    float: left;
    width: 80%;
  }

  &-right {
    float: right;
    width: 80%;
  }

  &-mid {
    float: left;
    width: 50%;

  }
}

.address-set {
  text-align: left;
}

.error-msg {
  text-align: center;
  color: $colorFive;
  font-size: $r-font;
}

/*images */

.imageOne {
  max-width: 300px;
  width: 100%;
  height: 300px;
  background-image: url(img/home_img.png);
  // background-size: 100%;
  background-repeat: no-repeat;
  // background-position: center;
  margin-top: $s;
  margin-left: $s;
}

.recaptcha {
  max-width: 300px;
  width: 100%;
  height: 100px;
  background-image: url(img/recaptcha.jpg);
  // background-size: 100%;
  background-repeat: no-repeat;
  // background-position: center;
  margin-top: $s;
  margin-left: $s;
}

.steps {
  max-width: 333px;
  margin: 0 auto;
  width: 100%;
  height: 70px;
  background-image: url(img/steps.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.steps2 {
  max-width: 333px;
  margin: 0 auto;
  width: 100%;
  height: 70px;
  background-image: url(img/steps2.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.steps3 {
  max-width: 333px;
  margin: 0 auto;
  width: 100%;
  height: 70px;
  background-image: url(img/steps3.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.benefit-bold {
  font-weight: bold;
  font-style: italic;
  // font-size: 0.9em;
  color: rgba(0, 0, 0, 0.54);
}
.table-header-cell, .MuiTableCell-head.table-header-cell {
  // background: $colorOne;
  background: #3c8cb9;
  color: #fff;
  border-left: 1px solid #fff;
  padding: 16px 10px!important;
}
.table-cell {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  padding: 16px 10px!important;
}
.table-header-cell.no-border-left {
  border-left: none;
}
/* .card-benefit-section {
  display: inline-block;
} */
.two-col-button {
  width: 50%!important;
  margin-bottom: 10px!important;
}
.button-margin {
  margin: 1%;
  margin-left: 0%;
}
.button-b-margin {
  margin-bottom: $s;
}
.user-type-icon {
  margin: 15px auto;
  display: block;
}
.user-type-spacer {
  height: 78px;
}

@media screen and (max-width: 820px){
  .main-footer {
    padding: 2em 80px 2em 20px;
  }
}

@media screen and (max-width: 767px) {

  .header-logo {
    width: 100%;
    display: block;
    margin: 0px auto 1em auto !important;
  }
  .logo-wrap {
    float: none;
  }
  .m-block {
    display: block;
    float: none;
    width: 100%;
    min-width: 100%;
  }
  // .main-content-wrapper{
  //     padding:$md ;
  // }
  .m-p-0 {
    padding: 0px;
  }
  .m-m-l-0 {
    margin-left: 0px;
  }
  .m-f-none {
    float: none;
  }
  .m-text-center {
    text-align: center;
  }
  .two-col-spaced, .three-col-spaced {
    min-width: 48%;
    width: 94%;
    margin: 3%;
  }
  .nine-tenth-mobile {
    width: 90%;
  }
  .m-block.two-col-spaced, .m-block.three-col-spaced {
    width: 100%;
    margin: 0px;
  }
  .m-block.two-col-spaced-nomargin {
    margin: 0px;
  }
  .m-block.two-sm-col-spaced-r {
    padding: 0px;
  }
  .MuiFormControl-root {
    margin-bottom: 15px !important;
  }
  form h5 {
    margin-bottom: 10px;
  }
  .two-col-button {
    width: 100%!important;
  }
  .two-col-button .button {
    margin: 0px 0px 15px 0px;
  }
}


@media screen and (max-width: 570px) {
  .MuiGrid-grid-xs-6 {
    min-width: 234px!important;
  }
}


/* iphone */

@media screen and (max-width: 414px) {
  h1 {
    font-size: $lg;
  }
  .header-logo {
    width: 100%;
    display: block;
    margin: 0px auto 1em auto !important;
  }
  .logo-wrap {
    float: none;
  }
  .button {
    font-size: $r-font;
    font-weight: 800;
  }
}